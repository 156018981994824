
import { AppEvents } from "@/control/app-events";
import { defineComponent } from "vue";
import { NotificationsController } from "@/control/notifications";
import { AuthController } from "@/control/auth";
import { GetAssetURL, Request } from "@/utils/request";
import { LendingAPI } from "@/api/api-lending";
import { Timeouts } from "@/utils/timeout";
import NotificationsModal from "@/components/modals/NotificationsModal.vue";

export default defineComponent({
  components: {
    NotificationsModal
  },
  name: "TopBar",
  emits: ['toggle-menu'],
  props: {
    dark: Boolean,
  },
  data: function () {
    return {
      hasNotifications: NotificationsController.UnreadCount > 0,
      displayNotifications: false,
      isFilled: false,
      image: AuthController.Image,
    };
  },
  methods: {
    openUserSettings: function () {
      AppEvents.Emit("account-settings-open");
    },

    openNotifications: function() {
      this.displayNotifications = true;
    },

    openPersonalForm: function() {
      this.$router.push({ name: "personal-form" });
    },

    openNewLoanRequest: function() {
      this.$router.push({ name: "loan-new-request" });
    },

    clickMenu: function () {
      this.$emit("toggle-menu");
    },

    notificationsChanged: function () {
      this.hasNotifications = NotificationsController.UnreadCount > 0;
    },

    getAsset: function (url: string) {
      return GetAssetURL(url);
    },

    onAuthStatusUpdate: function () {
      this.image = AuthController.Image;
    },

    handleLoanButtonClick: function () {
      this.isPersonalFormFilled().then(isFilled => {
        if (isFilled) {
          this.openNewLoanRequest();
        } else {
          this.openPersonalForm();
        }
      });
    },
    isPersonalFormFilled: function () {
      return new Promise((resolve, reject) => {
          Request.Pending("check-personal-form-data", LendingAPI.CheckPersonalFormData())
              .onSuccess(response => {
                  resolve(response.personalFormRequestExists);
              })
              .onRequestError(err => {
                  Request.ErrorHandler()
                      .add(401, "*", () => {
                          AppEvents.Emit("unauthorized");
                          reject(err);
                      })
                      .add(403, "*", () => {
                          this.$router.push({ name: "home" });
                          reject(err);
                      })
                      .add(404, "*", () => {
                          this.$router.push({ name: "home" });
                          reject(err);
                      })
                      .add("*", "*", () => {
                          // Retry
                          Timeouts.Set("check-personal-form-data", 2000, this.isPersonalFormFilled.bind(this));
                          reject(err);
                      })
                      .handle(err);
              })
              .onUnexpectedError(err => {
                  console.error(err);
                  // Retry
                  Timeouts.Set("check-personal-form-data", 2000, this.isPersonalFormFilled.bind(this));
                  reject(err);
              });
      });
  },
  
  },
  mounted: function () {
    this.$options.onAuthStatusUpdateH = this.onAuthStatusUpdate.bind(this);
    AppEvents.AddEventListener("auth-status-changed", this.$options.onAuthStatusUpdateH);

    this.$options.notificationsChangedH = this.notificationsChanged.bind(this);
    AppEvents.AddEventListener("notifications-status-changed", this.$options.notificationsChangedH);
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("auth-status-changed", this.$options.onAuthStatusUpdateH);
    AppEvents.RemoveEventListener("notifications-status-changed", this.$options.notificationsChangedH);
  },
});
