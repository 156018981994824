
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { AppEvents } from "@/control/app-events";
import { FocusTrap } from "../../utils/focus-trap";
import { AdvicesController } from "@/control/advices";

export default defineComponent({
    name: "HelpBox",
    emits: ["update:display"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
        displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            top: "",
            left: "",
            right: "",
            bottom: "",
            width: "",

            arrowPos: "top-left",
            content: "(Content)",
            followEl: "",
            zindex: 0,
        };
    },
    methods: {
        autoFocus: function () {
            const elem = this.$el.querySelector(".auto-focus");
            if (elem) {
                setTimeout(() => {
                elem.focus();
                }, 200);
            }
        },

        open: function (followEl: string, html: string, zindex: number) {
            this.followEl = followEl;
            this.content = this.$t(html);
            this.displayStatus = true;
            this.zindex = zindex;
            this.tick();
        },

        close: function () {
            this.displayStatus = false;
        },

        understood: function () {
            this.close();
            AdvicesController.understand(this.followEl);
        },

        disableTips: function () {
            this.close();
            AdvicesController.understandAll();
        },

        getArrowClass: function (pos) {
            return "help-box-body help-box-arrow-" + pos;
        },

        tick: function () {
            if (!this.displayStatus) {
                return;
            }

            var elemBounds;

            if (this.followEl) {
                var elem = document.querySelector(this.followEl);

                if (!elem || elem.offsetParent === null) {
                    this.displayStatus = false;
                    return;
                }

                elemBounds = elem.getBoundingClientRect();
            } else {
                this.displayStatus = false;
                return;
            }

            var windowH = window.innerHeight;
            var windowW = window.innerWidth;

            var cx = elemBounds.left + (elemBounds.width / 2);
            var cy = elemBounds.top + (elemBounds.height / 2);

            // Calc lines

            var a1 = (windowH / windowW);
            var b1 = 0;
            var slice1Right = (a1 * cx - cy + b1) > 0;

            var a2 = ((0 - windowH) / (windowW));
            var b2 = windowH;
            var slice2Right = (a2 * cx - cy + b2) < 0;

            var pageBottom = cy > (windowH / 2);
            var pageRight = cx > (windowW / 2);

            var pos = "top-left";
            var px, py;

            if (slice1Right && !slice2Right) {
                // Top
                px = elemBounds.left + (elemBounds.width / 2);
                py = elemBounds.top + elemBounds.height;

                if (pageRight) {
                    pos = "top-right";
                } else {
                    pos = "top-left";
                }
            } else if (!slice1Right && slice2Right) {
                // Bottom
                px = elemBounds.left + (elemBounds.width / 2);
                py = elemBounds.top;

                if (pageRight) {
                    pos = "bottom-right";
                } else {
                    pos = "bottom-left";
                }
            } else if (!slice1Right && !slice2Right) {
                // Left
                px = elemBounds.left + elemBounds.width;
                py = elemBounds.top + (elemBounds.height / 2);

                if (pageBottom) {
                    pos = "left-bottom";
                } else {
                    pos = "left-top";
                }
            } else {
                // Right
                px = elemBounds.left;
                py = elemBounds.top + (elemBounds.height / 2);

                if (pageBottom) {
                    pos = "right-bottom";
                } else {
                    pos = "right-top";
                }
            }

            this.arrowPos = pos;

            var takenWidth = 0;

            switch (pos) {
                case "top-left":
                    {
                        let l = px - 40;
                        let t = py + 4;
                        takenWidth = l;
                        this.left = l + "px";
                        this.top = t + "px";
                        this.right = "";
                        this.bottom = "";
                    }
                    break;
                case "top-right":
                    {
                        let r = (windowW - px) - 40;
                        takenWidth = r;
                        let t = py + 4;
                        this.right = r + "px";
                        this.top = t + "px";
                        this.left = "";
                        this.bottom = "";
                    }
                    break;
                case "bottom-left":
                    {
                        let l = px - 40;
                        takenWidth = l;
                        let b = (windowH - py) + 4;
                        this.left = l + "px";
                        this.bottom = b + "px";
                        this.right = "";
                        this.top = "";
                    }
                    break;
                case "bottom-right":
                    {
                        let r = (windowW - px) - 40;
                        takenWidth = r;
                        let b = (windowH - py) + 4;
                        this.right = r + "px";
                        this.bottom = b + "px";
                        this.left = "";
                        this.top = "";
                    }
                    break;
                case "left-top":
                    {
                        let l = px + 4;
                        takenWidth = l;
                        let t = py - 40;
                        this.left = l + "px";
                        this.top = t + "px";
                        this.right = "";
                        this.bottom = "";
                    }
                    break;
                case "left-bottom":
                    {
                        let l = px + 4;
                        takenWidth = l;
                        let b = (windowH - py) - 40;
                        this.left = l + "px";
                        this.bottom = b + "px";
                        this.right = "";
                        this.top = "";
                    }
                    break;
                case "right-top":
                    {
                        let r = (windowW - px) + 4;
                        takenWidth = r;
                        let t = py - 40;
                        this.right = r + "px";
                        this.top = t + "px";
                        this.left = "";
                        this.bottom = "";
                    }
                    break;
                case "right-bottom":
                    {
                        let r = (windowW - px) + 4;
                        takenWidth = r;
                        let b = (windowH - py) - 40;
                        this.right = r + "px";
                        this.bottom = b + "px";
                        this.left = "";
                        this.top = "";
                    }
                    break;
            }

            let w = Math.max(90, Math.min(460, windowW - takenWidth));
            this.width = w + "px";
        },
    },
    mounted: function () {
        this.$options.openH = this.open.bind(this);
        AppEvents.AddEventListener("help-box-open", this.$options.openH);

        this.$options.timer = setInterval(this.tick.bind(this), 1000);

        this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
    },
    beforeUnmount: function () {
        AppEvents.RemoveEventListener("help-box-open", this.$options.openH);
                    
        clearInterval(this.$options.timer);

        this.$options.focusTrap.destroy();
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$options.focusTrap.activate();
                nextTick(() => {
                this.$el.focus();
                });
            } else {
                this.$options.focusTrap.deactivate();
            }
        },
    },
});
